import Vue from 'vue'
// import VCharts from 'v-charts'
// Vue.use(VCharts)
import App from './App.vue'
import router from './router'
import './permission.js';

import store from './store'
//引入自动转rem
import 'lib-flexible/flexible.js'
// import './assets/fonts/fonts.css'
import 'nprogress/nprogress.css'
/**
 * 引入自定义指令
 */
import {install, trace} from '@/directives/auth'
//页面转场动画
import vueg from 'vueg-history'
//按需引入
import {
    Button,
    Calendar,
    Cell,
    CellGroup,
    Checkbox,
    Col,
    CountDown,
    Dialog,
    Divider,
    DropdownItem,
    DropdownMenu,
    Empty,
    Field,
    Form,
    GoodsAction,
    GoodsActionButton,
    GoodsActionIcon,
    Grid,
    GridItem,
    Icon,
    Image,
    List,
    NavBar,
    NoticeBar,
    Notify,
    Overlay,
    Picker,
    Popup,
    PullRefresh,
    Radio,
    RadioGroup,
    Row,
    Search,
    Sticky,
    SwipeCell,
    Switch,
    Tab,
    Tabs,
    Tag,
    Toast,
    TreeSelect,
    Uploader
} from "vant";

import VueCropper from 'vue-cropper'

Vue.use(VueCropper)
Vue.use(Empty)
Vue.use(Sticky)
Vue.use(Overlay)
Vue.use(NoticeBar)
Vue.use(SwipeCell)
Vue.use(TreeSelect)
Vue.use(PullRefresh)
Vue.use(Uploader)
Vue.use(Picker)
Vue.use(Popup)
Vue.use(Tag)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Switch)
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Checkbox)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Form)
Vue.use(CountDown)
Vue.use(Divider)
Vue.use(Field);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Icon);
Vue.use(NavBar);
Vue.use(Search);
Vue.use(List);
Vue.use(Toast);
Vue.use(Notify);

Vue.use(Button);
Vue.use(Col);
Vue.use(Row);
Vue.use(Calendar);
Vue.use(Dialog);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Image)
Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
// Vue.component('VPullRefreshList', PullRefreshList)
// Vue.component('VBackControl', BackControl)
Vue.config.productionTip = false
Vue.use(install)
Vue.use(trace)

Vue.use(vueg, router) // 传入实例化后的router, Options为可选的插件配置
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
Vue.use(Button)
