<template>
  <div id="app">
    <!--    <keep-alive>-->
    <router-view v-transition/>
    <!--    </keep-alive>-->
  </div>
</template>
<script>
import {getCenter} from "@/api/register/register";

export default {
  name: "App",
//组件引入
  components: {},
  //父子组件传值
  props: {},
  //计算属性，可以缓存，避免每次计算都要重新计算
  computed: {},
  //filters 过滤器
  filters: {},
  //数据
  data() {
    return {}
  },
  //在模板渲染成html前调用,即通常初始化某些属性值,然后再渲染成视图。
  created() {
    // this.getOpenIdOrEmployeeId2()
    this.getCenter()
  },
  //在模板渲染成html后调用，通常是初始化页面完成后，再对html的dom节点进行一些需要的操作。
  mounted() {
  },
  //方法 处理逻辑
  methods: {
    /**
     * 获取教师人员id或者openId
     */
    getOpenIdOrEmployeeId2() {
      let urlData = this.ParseUrlSearch()
      if (urlData.openId) {
        //进入这里是访问者身份
        this.$store.commit('SET_OPEN_ID', urlData.openId)
        this.$store.commit('SET_WECHAT_ID', urlData.weixinId)
        this.$router.replace({path: '/selectSchool'})
        return
      }
      if (urlData.employeeid) {
        //教师身份
        this.$store.commit('SET_TEACHER_DATA', {id: urlData.employeeid, schoolId: urlData.schoolId})
        this.$router.replace({path: '/teacherVisitorList'})
      }
    },
    /**
     * 获取学校id
     */
    async getCenter() {
      let urlData = this.ParseUrlSearch()
      if (urlData.centerId) {
        this.$toast.loading({duration: 0, message: '获取企业信息中...'})
        let res = await getCenter({centerId: urlData.centerId})
        if (res.code === 0 && res.data) {
          this.$toast.clear()
          this.$store.commit('SET_ENTER_ID', urlData.centerId)//将输入的企业编号存入store
          this.$store.commit('SET_CENTER_DATA', res.data)//将输入的企业信息存入store
        } else {
          this.$toast.fail('未找到相关企业、请检查！')
        }
        await this.$router.replace({path: '/register'})
      }
    },
    /**
     * URL参数反序列化
     */
    ParseUrlSearch() {
      return location.search.replace(/(^\?)|(&$)/g, "").split("&").reduce((t, v) => {
        const [key, val] = v.split("=");
        t[key] = decodeURIComponent(val);
        return t;
      }, {});
    },
    /*    /!**
         * 切割教师人员id或者openId
         *!/
        getOpenIdOrEmployeeId() {
          let teacher = window.location.href.split('employeeid=')
          let openId = window.location.href.split('openId=')
          if (teacher.length >= 2) {
            //进入这里是教师身份
            //再次切割
            let teacherData = teacher[teacher.length - 1].split('&schoolId=')
            this.$store.commit('SET_TEACHER_DATA', {id: teacherData[0], schoolId: teacherData[1]})
            this.$router.replace({path: '/teacherVisitorList'})
            return
          }
          if (openId.length >= 2) {
            console.log(openId, 'openId');
            this.$router.replace({path: '/selectSchool'})
            //进入这里是访问者身份
            this.$store.commit('SET_OPEN_ID', openId[openId.length - 1])
            return
          }
          // this.$router.replace({path: '/error'})
        },*/
  }
}
</script>
<style>
* {
  -webkit-overflow-scrolling: touch;
}

#app {
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

#nprogress .bar {
  background: linear-gradient(right bottom, #108ee9 0%, #87d068 100%);
}
</style>
