import Vue from 'vue'
import Vuex from 'vuex'
import register from "@/store/register";

Vue.use(Vuex)

export default new Vuex.Store({
    //相当于data
    state: {
        wechatId: '',//微信id
        userType: '',
        teacherData: {},
        schoolData: {},//选择的学校数据
        selectVisitorUserInfo: {},//选择预约人信息
        openId: '',//用户微信openId
        visitorDetails: {}//预约详情
        /*count: 1000,
        userInfo: Array,
        list: sessionStorage.getItem('list') ? sessionStorage.getItem('list') : []*/
    },
    mutations: {
        SET_WECHAT_ID: ((state, data) => {
            state.wechatId = data
            sessionStorage.setItem('wechatId', JSON.stringify(data))
        }),
        SET_USER_TYPE: ((state, data) => {
            state.userType = data
            sessionStorage.setItem('userType', state.userType)
        }),
        SET_SCHOOL_DATA: ((state, data) => {
            state.schoolData = data
            sessionStorage.setItem('schoolData', JSON.stringify(state.schoolData))
        }),
        SET_SELECT_VISITOR_USER_INFO: ((state, data) => {
            state.selectVisitorUserInfo = data
            sessionStorage.setItem('selectVisitorUserInfo', JSON.stringify(state.selectVisitorUserInfo))
        }),
        SET_OPEN_ID: ((state, data) => {
            state.userType = 1
            state.openId = data
            localStorage.setItem('openId', JSON.stringify(state.openId))
            localStorage.setItem('userType', state.userType)
        }),
        SET_VISITOR_DETAILS: ((state, data) => {
            state.visitorDetails = data
            sessionStorage.setItem('visitorDetails', JSON.stringify(state.visitorDetails))
        }),
        SET_TEACHER_DATA: ((state, data) => {
            state.userType = 2
            state.teacherData = data
            sessionStorage.setItem('teacherData', JSON.stringify(state.teacherData))
            localStorage.setItem('userType', state.userType)
        })
    },
    //异步操作
    actions: {},
    modules: {register},
    getters: {
        //获取微信id
        getWechatId: state => {
            state.wechatId = JSON.parse(sessionStorage.getItem('wechatId'))
            return state.wechatId
        },
        //获取学校数据
        getSchoolData: state => {
            state.schoolData = JSON.parse(sessionStorage.getItem('schoolData'))
            return state.schoolData
        },
        //获取选择的预约人信息
        getSelectVisitorUserInfo: state => {
            state.selectVisitorUserInfo = JSON.parse(sessionStorage.getItem('selectVisitorUserInfo')) || {}
            return state.selectVisitorUserInfo
        },
        //获取openId
        getOpenId: state => {
            state.openId = JSON.parse(localStorage.getItem('openId'))
            return state.openId
        },
        //获取预约详情
        getVisitorDetails: state => {
            state.visitorDetails = JSON.parse(sessionStorage.getItem('visitorDetails'))
            return state.visitorDetails
        },
        //获取教师数据
        getTeacherData: state => {
            state.teacherData = JSON.parse(sessionStorage.getItem('teacherData'))
            return state.teacherData
        },
        //获取用户类型
        getUserType: state => {
            state.userType = localStorage.getItem('userType')
            return state.userType
        }
        /* GET_LIST: state => {
             return state.list
         },
         GET_USERINFO: state => {
             return state.userInfo
         }*/
    }
})
