import request from "@/request/http"

/**
 * 获取企业信息
 * @param params
 * @returns {*}
 */
export function getCenter(params){
    return request({
        url:'/commnt/MBalancecenterController/get',
        method:'post',
        data:params,
    })
}
/**
 * 获取部门信息
 * @param params
 * @url /commnt/BaseDeptController/getListByCenterId
 * @returns {*}
 */
export function getDept(params){
    return request({
        url:'/commnt/BaseDeptController/getListByCenterId',
        method:'post',
        data:params,
    })
}
/**
 * 获取个人信息是否存在(通过用户名和手机号码)
 * @param params
 * @url /commnt/BaseEmpController/getoneByTelorName
 * @returns {*}
 */
export function getUserInfoByUserNameAndPhone(params){
    return request({
        url:'/commnt/BaseEmpController/getoneByTelorName',
        method:'post',
        data:params,
    })
}
/**
 * 申请注册
 * @param params
 * @url /commnt/BaseEmpController/addEmp
 * @returns {*}
 */
export function addEmp(params){
    return request({
        url:'/commnt/BaseEmpController/addEmp',
        method:'post',
        data:params,
    })
}
/**
 * 申请修改
 * @param params
 * @url /commnt/BaseEmpController/updateEmp
 * @returns {*}
 */
export function updateEmp(params){
    return request({
        url:'/commnt/BaseEmpController/updateEmp',
        method:'post',
        data:params,
    })
}
/**
 * 申请列表
 * @param params
 * @url /commnt/BaseEmpController/getEmpList
 * @returns {*}
 */
export function getEmpList(params){
    return request({
        url:'/commnt/BaseEmpController/getEmpList',
        method:'post',
        data:params,
    })
}
/**
 * 撤销申请
 * @param params
 * @url /commnt/BaseEmpController/deleteEmp
 * @returns {*}
 */
export function deleteEmp(params){
    return request({
        url:'/commnt/BaseEmpController/deleteEmp',
        method:'post',
        data:params,
    })
}

