/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from 'axios';
import router from '../router';
import store from '../store/index';
import {Toast} from 'vant';
//进度条
/*import Nprogress from 'nprogress' 	//js实现进度条效果
Nprogress.configure({showSpinner: false})*/

/**
 * 提示函数
 * 禁止点击蒙层、显示一秒后关闭
 */
const tip = msg => {
    Toast({
        type: 'fail',
        message: msg,
        duration: 1000,
        forbidClick: true
    });
}

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
    router.replace({
        path: '/login',
        query: {
            redirect: router.currentRoute.fullPath
        }
    });
}

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 * @param other
 */
const errorHandle = (status, other) => {
    // 状态码判断
    switch (status) {
        // 401: 未登录状态，跳转登录页
        case 401:
            toLogin();
            break;
        // 403 token过期
        // 清除token并跳转登录页
        case 403:
            tip('登录过期，请重新登录');
            localStorage.removeItem('token');
            store.commit('loginSuccess', null);
            setTimeout(() => {
                toLogin();
            }, 1000);
            break;
        // 404请求不存在
        case 404:
            tip('请求的资源不存在');
            break;
        default:
            console.log(other);
    }
}

const actionMapping = {
    'get': 'view',
    'post': 'add',
    'put': 'edit',
    'delete': 'delete'
}

// 创建axios实例 设置请求超时时间8秒。8秒请求不到就抛错误
var instance = axios.create(
    {
        timeout: 1000 * 15,
    }
);
// 设置post请求头
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
if (process.env.NODE_ENV === 'production') {
    instance.defaults.baseURL = process.env.VUE_APP_URL?process.env.VUE_APP_URL:''
}
/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
    config => {
        // Nprogress.start()
        //判断是否有权限
        // const action = actionMapping[config.method]
        // console.log(router.currentRoute.meta);
        // const currentRole = router.currentRoute.meta.promise
        // if (currentRole && currentRole.indexOf(action) == -1) {
        //     alert('没有权限')
        //     console.log('没有权限')
        //     return Promise.reject(new Error('没有权限'))
        // }
        // 登录流程控制中，根据本地是否存在token判断用户的登录情况
        // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
        // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
        // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
        const token = store.state.token;
        token && (config.headers.Authorization = token);
        // track(JSON.stringify({type: 'request', url: config.url, des: '请求接口'}))
        //判断非权限内操作
        return config;
    },
    error => Promise.error(error))

// 响应拦截器
instance.interceptors.response.use(
    // 请求成功
    res => {
        // Nprogress.done()
        if (res.status === 200) {
            console.log(res, 'res');
            // track(JSON.stringify({type: 'request success', url: res.config.url, des: '接口请求成功'}))
            return Promise.resolve(res.data)
        }
        return Promise.reject(res.data)
        // return res.status === 200 ? Promise.resolve(res.data) : Promise.reject(res.data)
    },
    // 请求失败
    error => {
        const {response} = error;
        if (error.request) {
            if (error.request.readyState == 4 && error.request.status == 0) {
                //请求超时在这里重新请求
                // instance.request(error.config)
                tip('请求超时,请尝试手动刷新或重新。')
            }
        }
        if (response) {
            // 请求已发出，但是不在2xx的范围
            errorHandle(response.status, response.data.message);
            return Promise.reject(response);
        } else {
            // 处理断网的情况
            // eg:请求超时或断网时，更新state的network状态
            // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
            // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
            if (!window.navigator.onLine) {
                store.commit('changeNetwork', false);
            } else {
                return Promise.reject(error);
            }
        }
    });
export default instance;
