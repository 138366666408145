let ua = navigator.userAgent.toLowerCase();
//android终端
let isAdr = ua.indexOf('android') > -1 || ua.indexOf('Adr') > -1; //ios终端
var isWeixin = ua.indexOf('micromessenger') !== -1;
var isIos = (ua.indexOf('iphone') !== -1) || (ua.indexOf('ipad') !== -1);
export default {
    ua,
    isAdr,
    isIos,
    isWeixin,
}
