import router from "@/router";

import system from "@/utils/system";
import NProgress from "nprogress";
import {track} from "@/utils/buried/sendData";

router.beforeEach((to, from, next) => {
    NProgress.start()
    //判断是否微信客户端打开
    if (!system.isWeixin) {
        // window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=888"
    }
    if (to.meta.title) { //判断是否有标题
        document.title = to.meta.title
    }
    // to.path!=from.path?track(JSON.stringify({type:'路由跳转',url:to.path,title:to.meta.title,userId:'123'})):
    if (from.path == '/') {
        //代表当前路由是刷新
        track(JSON.stringify({type: '刷新页面', url: to.path, title: to.meta.title, userId: '123'}))
    } else {
        track(JSON.stringify({
            type: '进入新页面',
            oldUrl: from.path,
            oldTitle: from.meta.title,
            newUrl: to.path,
            title: to.meta.title,
            userId: '123'
        }))
    }
    track(JSON.stringify({type:'路由跳转',url:to.path,title:to.meta.title,userId:'123'}))
    next() //执行进入路由，如果不写就不会进入目标页
})

router.afterEach((to, from, next) => {
    NProgress.done()
});
