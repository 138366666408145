const register = {
    //相当于data
    state: {
        //企业编号
        centerId: sessionStorage.getItem('centerId') !== null ? JSON.parse(sessionStorage.getItem('centerId')) : '',
        centerData: sessionStorage.getItem('centerData') !== null ? JSON.parse(sessionStorage.getItem('centerData')) : {},
        deptList: sessionStorage.getItem('deptList') !== null ? JSON.parse(sessionStorage.getItem('deptList')) : [],
        userData: sessionStorage.getItem('userData') !== null ? JSON.parse(sessionStorage.getItem('userData')) : {},
        path: sessionStorage.getItem('path') !== null ? JSON.parse(sessionStorage.getItem('path')) : '',
        path2: sessionStorage.getItem('path2') !== null ? JSON.parse(sessionStorage.getItem('path2')) : '',
    },
    mutations: {
        SET_ENTER_ID: ((state, data) => {
            state.centerId = data
            sessionStorage.setItem('centerId', JSON.stringify(data))
        }),
        SET_CENTER_DATA: ((state, data) => {
            state.centerData = data
            sessionStorage.setItem('centerData', JSON.stringify(data))
        }),
        SET_USER_DATA: ((state, data) => {
            state.userData = data
            sessionStorage.setItem('userData', JSON.stringify(data))
        }),
        SET_PATH: ((state, data) => {
            state.path = data
            sessionStorage.setItem('path', JSON.stringify(data))
        }),
        SET_PATH2: ((state, data) => {
            state.path2 = data
            sessionStorage.setItem('path2', JSON.stringify(data))
        }),
        SET_DEPT_LIST: ((state, data) => {
            state.deptList = data
            sessionStorage.setItem('deptList', JSON.stringify(data))
        }),
    },
    //异步操作
    actions: {
        SET_USER_DATA({commit, state}, data) {
            return new Promise((resolve, reject) => {
                commit('SET_USER_DATA', data)
                resolve()
            })
        },
    },
    modules: {},
    getters: {
        /**
         * 获取企业编号
         * @param state
         * @returns {string|string}
         */
        getCenterId: state => {
            return state.centerId
        },
        /**
         * 获取企业信息
         * @param state
         * @returns {string|string}
         */
        getCenterData: state => {
            return state.centerData
        },
        /**
         * 获取个人数据信息
         * @param state
         * @returns {string|string}
         */
        getUserData: state => {
            return state.userData
        },
        /**
         * 获取文件预览路径
         * @param state
         * @returns {string|string}
         */
        getPath: state => {
            return state.path
        },
        /**
         * 获取文件预览路径2
         * @param state
         * @returns {string|string}
         */
        getPath2: state => {
            return state.path2
        },
        getDeptList: (state, id) => {
            return state.deptList
        },
        getDeptName: (state) => (id) => {
            console.log(id, 'id');
            if (!id) {
                return '未知'
            }
            let data = state.deptList.find(item => item.id == id)
            if (!data) {
                return '未知'
            }
            if (data.departmentName) {
                return data.departmentName
            }
            return '未知'
        }
    }
}
export default register
