import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/SelectEnter'
    },
    {
        path: '/SelectEnter',
        name: 'SelectEnter',
        meta: {
            title: "个人简介记录"
        },
        component: () => import(/* webpackChunkName: "SelectEnter" */'../views/register/SelectEnter.vue')
    },
   /* {
        path: '/faceCamera',
        name: 'faceCamera',
        meta: {
            title: "拍照"
        },
        component: () => import(/!* webpackChunkName: "faceCamera" *!/'../views/register/faceCamera.vue')
    },*/
    {
        path: '/register',
        name: 'register',
        meta: {
            title: "注册"
        },
        component: () => import(/* webpackChunkName: "register" */'../views/register/Register.vue')
    },
    {
        path: '/RegisterList',
        name: 'RegisterList',
        meta: {
            title: "注册列表"
        },
        component: () => import(/* webpackChunkName: "RegisterList" */'../views/register/RegisterList.vue')
    },
    {
        path: '/RegisterDetails',
        name: 'RegisterDetails',
        meta: {
            title: "注册详情"
        },
        component: () => import(/* webpackChunkName: "RegisterDetails" */'../views/register/RegisterDetails.vue')
    },
    {
        path: '*',
        redirect: '/error'
    },
  /*  {
        path: '/selectSchool',
        name: 'selectSchool',
        meta: {
            title: "选择学校"
        },
        component: () => import(/!* webpackChunkName: "selectSchool" *!/'../views/appointment/selectSchool.vue')
    },
    {
        path: '/SubscribeList',
        name: 'SubscribeList',
        meta: {
            title: "访客预约"
        },
        component: () => import(/!* webpackChunkName: "SubscribeList" *!/'../views/appointment/SubscribeList.vue')
    },
    {
        path: '/visitor',
        name: 'visitor',
        meta: {
            title: "访客预约"
        },
        component: () => import(/!* webpackChunkName: "visitor" *!/'../views/appointment/Visitor.vue')
    },
    {
        path: '/visitorSuccess/:id',
        name: 'VisitorSuccess',
        meta: {
            title: '预约结果',
        },
        component: () => import(/!* webpackChunkName: "visitorSuccess" *!/'../views/appointment/VisitorSuccess.vue')
    },
    {
        path: '/visitorDetails',
        name: 'VisitorDetails',
        meta: {
            title: '预约详情',
        },
        component: () => import(/!* webpackChunkName: "visitorDetails" *!/'../views/appointment/VisitorDetails.vue')
    },
    {
        path: '/visitorList',
        name: 'visitorList',
        meta: {
            title: '预约记录',
        },
        component: () => import(/!* webpackChunkName: "visitorList" *!/'../views/appointment/VisitorList.vue')
    },
    {
        path: '/teacherVisitorList',
        name: 'TeacherVisitorList',
        meta: {
            title: '访问记录',
        },
        component: () => import(/!* webpackChunkName: "teacherVisitorList" *!/'../views/appointment/TeacherVisitorList.vue')
    },*/
    {
        path: '/error',
        name: 'error',
        meta: {
            title: '出错啦',
        },
        component: () => import(/* webpackChunkName: "error" */'../views/appointment/error.vue')
    },
]

const router = new VueRouter({
    mode: 'history',  //去掉url中的#
    routes,
    base:'/caiji'
})
/*router.beforeEach((to, from, next) => { //beforeEach是router的钩子函数，在进入路由前执行

    if (to.meta.title) { //判断是否有标题
        document.title = to.meta.title
    }
    // to.path!=from.path?track(JSON.stringify({type:'路由跳转',url:to.path,title:to.meta.title,userId:'123'})):
    if (from.path == '/') {
        //代表当前路由是刷新
        track(JSON.stringify({type: '刷新页面', url: to.path, title: to.meta.title, userId: '123'}))
    } else {
        track(JSON.stringify({
            type: '进入新页面',
            oldUrl: from.path,
            oldTitle: from.meta.title,
            newUrl: to.path,
            title: to.meta.title,
            userId: '123'
        }))
    }
    // track(JSON.stringify({type:'路由跳转',url:to.path,title:to.meta.title,userId:'123'}))
    next() //执行进入路由，如果不写就不会进入目标页
})
router.afterEach(() => {

})*/
/*window.onbeforeunload = e => {      //刷新时弹出提示
    console.log(11)
    return ''
};*/
export default router
