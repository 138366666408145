/**
 * 点击类click.js
 *用户的点击行为没有曝光行为频繁，所以简单处理，每次点击进行埋点上报。
 */
import {track} from './sendData'

export default class Click {
    add(entry) {
        const tp = entry.el.attributes['track-params'].value
        entry.el.addEventListener('click', function () {
            track(tp)
        })
    }
}