// import config from './config'

/**
 * 事件上报
 * 发送数据给后台
 * @param {Object} params
 */
export function track(params) {
    // console.log(params,'track params');
    // 这里自己封装fetch或者axios，在拦截器中实现公共参数上报
    if(params.length>0) {
        // console.log(JSON.parse(params), '数据上报后台')
    }
    // console.log(`Track data to server /*$*/{config.serverUrl}: ${JSON.stringify(params)}`)
}
