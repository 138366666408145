export function install(Vue, options = {}) {
    Vue.directive('Permission', {
        inserted(el, binding) {
            if (binding.value.effect === 'disabled') {
                el.disabled = true
                el.classList.add('van-button--disabled')
            } else {
                el.style.display = "none"
            }
        }
    })
}


import Exposure from '@/utils/buried/exposure'
import Click from '@/utils/buried/click'

export function trace(Vue, options = {}) {
// 实例化曝光和点击
    const exp = new Exposure()
    const cli = new Click()
    Vue.directive('track', {
        // 调用指令声明周期钩子函数bind，其他钩子函数请移步官网
        bind(el, binding) {
            // 获取指令参数
            const {arg} = binding
            arg.split('|').forEach(item => {
                // 点击
                if (item === 'click') {
                    // console.log('点击')
                    cli.add({el})
                } else if (item === 'exposure') {
                    exp.add({el})
                }
            })
        }
    })
}
